import {DateTime} from 'luxon';

export function isISODateTime(value: string) {
    return DateTime.fromISO(value).isValid;
}

export function formatISODateTime(value: string | Date | undefined | null): string | Date | undefined {
    if (value) {
        const dateTime = typeof value === "string" ? DateTime.fromISO(value) : DateTime.fromJSDate(value);
        if (dateTime.isValid) {
            return dateTime.toFormat('yyyy.MM.dd. HH:mm:ss')
        } else {
            return value
        }
    }
}

export function formatISODate(value: string | undefined | null): string | undefined {
    if (value) {
        const dateTime = DateTime.fromISO(value);
        if (dateTime.isValid) {
            return dateTime.toFormat('yyyy.MM.dd.')
        } else {
            return value
        }
    }
}

export function formatBytes(value: number | undefined | null): string | undefined {
    if (value) {
        const size = value / 1024;

        return size > 1000.0
            ? `${(size / 1024).toFixed(2)} MB`
            : `${size.toFixed(2)} kb`;
    }
}
